/* React Kanban */
body .react-kanban-column {
    z-index: 3;
    background-color: #fff; /* Gray/Gray-100 */
    box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
    border-radius: 20px;
}
body.dark .react-kanban-column {
    background-color: #111c44; /* Gray/Gray-100 */
    border-radius: 20px;
    box-shadow: none;
}
.react-kanban-column {
    overflow: hidden;
    width: 310px;
    @media screen and (min-width: 768px) {
        width: 500px;
    }
}
.react-kanban-card-adder-form {
    width: 100%;
    border-radius: 15px;
}
.react-kanban-card-adder-button {
    display: none;
    border-radius: 15px;
}
/* Full Calendar */

/* Container for the calendar header */
.fc-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e0e0e0;
}

/* Prev and Next buttons */
.fc-prev-button,
.fc-next-button {
    background-color: #ff0000; /* Primary flat color */
    border: none;
    color: #fff;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: #efefef;
}

.fc .fc-toolbar {
    background-color: transparent;
    border-bottom: transparent;
    margin-bottom: 0px !important;
}

.fc .fc-toolbar .fc-next-button {
    color: black;
    background-color: transparent;
}

.fc .fc-toolbar .fc-prev-button {
    color: black;
    background-color: transparent;
}

.fc .fc-toolbar .fc-next-button {
    color: black;
    background-color: transparent;
}

/* Title in the center */
.fc .fc-toolbar .fc-toolbar-title {
    font-size: 20px;
    margin: 0;
}

/* Adjust button spacing */
.fc-prev-button {
    margin-right: 50px;
    background-color: red;
}

.fc-next-button {
    margin-left: 50px;
}
a.fc-daygrid-day-number {
    width: 100%;
    text-align: center;
}
a.fc-daygrid-day-number {
    width: 100%;
    text-align: center;
}
a.fc-daygrid-day-number {
    width: 100%;
    font-size: 11px;
    text-align: right;
}
div.fc-daygrid-event-harness {
    top: unset;
}
.fc-daygrid-event-harness .fc-daygrid-event-harness-abs {
    top: 0px;
    bottom: unset;
}
.fc-daygrid-block-event .fc-event-title {
    font-weight: 100;
}
.fc-daygrid-event {
    border-radius: 10px !important;
    height: 50%;
    display: flex !important;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.fc-daygrid-event .fc-event-main {
    margin-top: 0px;
    margin-bottom: auto;
}

body.dark .fc-theme-standard td {
    border-color: #ffffff1a !important;
}

.fc .fc-scrollgrid-section-header > *,
.fc .fc-scrollgrid-section-footer > * {
    border-right: 0px !important;
    font-size: 11px;
}
.fc-theme-standard .fc-scrollgrid {
    border: 1px solid #e0e5f2 !important;
    background-color: #f8f9fb;
    border-radius: 10px;
}
body.dark .fc-theme-standard .fc-scrollgrid {
    border-color: #ffffff1a !important;
}

body .fc-col-header-cell.fc-day.fc-day-mon {
    border-right: 0px;
    border-left: 0px;
}
body.dark .fc-col-header-cell.fc-day.fc-day-mon {
    border-right: 0px;
    border-left: 0px;
    border-color: #ffffff1a;
}

body .fc-col-header-cell.fc-day.fc-day-tue {
    border-left: 0px;
    border-right: 0px;
}
body.dark .fc-col-header-cell.fc-day.fc-day-tue {
    border-left: 0px;
    border-right: 0px;
    border-color: #ffffff1a;
}

body .fc-col-header-cell.fc-day.fc-day-wed {
    border-right: 0px;
    border-left: 0px;
}
body.dark .fc-col-header-cell.fc-day.fc-day-wed {
    border-right: 0px;
    border-left: 0px;
    border-color: #ffffff1a;
}

body .fc-col-header-cell.fc-day.fc-day-thu {
    border-left: 0px;
    border-right: 0px;
}
body.dark .fc-col-header-cell.fc-day.fc-day-thu {
    border-left: 0px;
    border-right: 0px;
    border-color: #ffffff1a;
}

body .fc-col-header-cell.fc-day.fc-day-fri {
    border-right: 0px;
    border-left: 0px;
}
body.dark .fc-col-header-cell.fc-day.fc-day-fri {
    border-right: 0px;
    border-left: 0px;
    border-color: #ffffff1a;
}

body .fc-col-header-cell.fc-day.fc-day-sat {
    border-left: 0px;
    border-right: 0px;
}
body.dark .fc-col-header-cell.fc-day.fc-day-sat {
    border-left: 0px;
    border-right: 0px;
    border-color: #ffffff1a;
}

body .fc-col-header-cell.fc-day.fc-day-sun {
    border-left: 0px;
    border-right: 0px;
}
body.dark .fc-col-header-cell.fc-day.fc-day-sun {
    border-left: 0px;
    border-right: 0px;
    border-color: #ffffff1a;
}

body .fc-scrollgrid.fc-scrollgrid-liquid > td {
    border: 0px;
}
body.dark .fc-scrollgrid.fc-scrollgrid-liquid > td {
    border: 0px;
    border-color: #ffffff1a;
}

.fc .fc-scrollgrid-section > td {
    border: 0;
}
